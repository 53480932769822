@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

*,
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}



html{
    
    font-size: 62.5%;
}

body{
    box-sizing: border-box;
    background-color: #e1e2e0;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    
}

a{
    text-decoration: none;
    color: rgba(38, 70, 83, 0.8);
    padding-right: 2rem;
    transition: all .4s ease-in-out;
    
}


a:hover{
    transform: translateY(-.2rem);
}

.header {
    display: flex;
    flex-direction: column;
    height: 60vh;
    background-image: linear-gradient(to right bottom, rgba(189, 195, 199, .6), rgba(44, 62, 80, .6)), url(./images/friends.jpg);
    background-size: cover;
    background-position: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    align-items: center;

    justify-content: center; }

     
.heading{
    color: white;
    letter-spacing: .2rem; 
    font-weight: 200;
    font-size: 4rem;
    text-align: center;
    animation: moveInTop .8s ease;
}

.heading-sm{
    font-size: 2rem;

}

.navbar{
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  height: 5vh;
  z-index: 20;
}

.blog-preview a{
    text-decoration: none;
}

.content{
    font-size: 1.6rem;
    /* margin-top: 5vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}

.form-content{
    font-size: 1.6rem;
    width: 70%;
    margin: 10vh auto;


   
}

.blog-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem 2rem;
    grid-auto-rows: 35vh;
    margin: 5vh auto;
    width: 90%;
}

.blog{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
    "text text text text"
    "text text text text"
    "delete delete delete delete";
    padding: 2rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transition: all 0.4s ease-in-out;
}

.blog-text{
    padding: 1rem 1rem 2rem 1rem;
}

.blog-author{
    font-style: italic;
    font-size: 1.2rem
}

.delete { grid-area: delete; }
.text { grid-area: text; }

.blog:hover{
    transform: translateY(-.5rem);
}

.sent{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}


button{
    border: none;
    border-bottom: 2px solid #176285;
    padding: .5rem;
    color: #176285;
    font-weight: 500;
    transition: all .4s ease-in-out;
    cursor: pointer;
    background-color: #e1e2e0;
    outline: none;
}

.login{
    background-color: #fff;
    padding: 2rem;
    border: none;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 1.6rem;
    margin-top: 5vh;
}

.add{
    background-color: #fff;
    padding: .7rem;
    border: none;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 1.6rem;
}

.add__plus{
    margin-top: 5vh;
    margin-left: 5vw;
    padding: 1rem 1.5rem;
    font-size: 1.6rem;
    
}

.delete{
    background-color: #6d7d84;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 1.6rem;
    margin: 0 auto;
    margin-top: 10vh;
    width: 60%;
}

button:hover{
    background-color: #176285;
    color: #fff;
    transform: translateY(-.1rem);
}

form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 5rem;
    /* border-radius: 50% 20% / 10% 40%; */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

input{
    border: none;
    border-radius: 10px;
    background-color: #e2eaeb;
    padding: 1rem;
    outline: none;
    color: #176285;
    margin: 1rem;
    text-align: center;
    width: inherit;
}

.btn-add{
    background-color: #6d7d84;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 1.6rem;
    margin: 2rem auto;
    width: 25%;
}

@keyframes moveInTop{
    0%{
        opacity: 0;
        transform: translateY(-10rem);
    }
    
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}